// **************
// View More機能
// **************

const viewMoreToggle = () => {
  const lists = document.querySelectorAll('.js-view-more-list');
  const viewMoreBtns = document.querySelectorAll('.js-view-more-button');

  viewMoreBtns.forEach(function (btn, index) {
    btn.addEventListener('click', function () {
      let viewMoreList = lists[index];

      // デフォルトでは２件表示し、btnクリックでその他を表示非表示
      for (let i = 1; i < viewMoreList.children.length; i++) {
        viewMoreList.children[i].classList.toggle('is-open');
      }

      btn.classList.toggle("is-show");
    });
  });
}


// **************
// フッター固定
// **************

const adjustFooterPosition = () => {
  const bodyHeight = document.body.clientHeight;
  const windowHeight = window.innerHeight;
  const footer = document.getElementById('js-footer');
  const footerHeight = footer.clientHeight

  if (bodyHeight < windowHeight) {
    footer.classList.add('is-fixed');
  } else {
    footer.classList.remove('is-fixed');
  }
}


// =================================================================

window.addEventListener('load', () => {
  viewMoreToggle()
  adjustFooterPosition();
});

window.addEventListener('resize', () => {
  adjustFooterPosition();
});